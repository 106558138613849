import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import jwt_decode from 'jwt-decode';

// Api
import { parseCookie, CookieType } from '@utils/api';
import { RoleEnum, TokenType } from '@api/types';
import { useFullProfile } from '@api/users';

// Layout
import LayoutMenu from '@components/layout/layoutMenu/LayoutMenu';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const IndexPage = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const router = useRouter();

  const [cookie, setCookie] = useState<CookieType | null>(null);

  const decodedToken: TokenType | null = cookie?.token ? jwt_decode(cookie?.token) : null;
  const userInfo = useFullProfile({
    userId: (decodedToken as TokenType)?.user_id,
    skip: !cookie,
  });

  useEffect(() => {
    const parsedCookie = parseCookie(document.cookie);
    setCookie(parsedCookie);

    if (!parsedCookie.token) {
      router.push('/marketplace', '');
    }
  }, []);

  useEffect(() => {
    if (userInfo?.data?.role === RoleEnum.Reseller) {
      router.push('/stock', '');
    } else {
      router.push('/marketplace', '');
    }
  }, [userInfo]);

  return <LayoutMenu>{}</LayoutMenu>;
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, ['common'])),
  },
});

export default IndexPage;
